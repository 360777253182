import AuthAPI from '@/api/auth';
import router from '@/router';
import { jwtDecode } from 'jwt-decode';

const USERNAME_CLAIM = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name";
const ROLES_CLAIM = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
const PERMISSIONS_CLAIM = "Permission";
const MERCHANT_CLAIM = "MerchantId";

const state = {
  user: null,
  accessToken: localStorage.getItem('accessToken') || '',
  refreshToken: localStorage.getItem('refreshToken') || '',
};

const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
  SET_ACCESS_TOKEN(state, token) {
    state.accessToken = token;
    localStorage.setItem('accessToken', token);
  },
  SET_REFRESH_TOKEN(state, token) {
    state.refreshToken = token;
    localStorage.setItem('refreshToken', token);
  },
  CLEAR_USER_DATA(state) {
    state.user = null;
    state.accessToken = '';
    state.refreshToken = '';
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  },
};

const actions = {
  async login({ commit, dispatch }, credentials) {
    try {
      const response = await AuthAPI.login(credentials);
      const { accessToken, refreshToken } = response.data;
      commit('SET_ACCESS_TOKEN', accessToken);
      commit('SET_REFRESH_TOKEN', refreshToken);
      await dispatch('fetchUser');
      router.push('/');
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async logout({ commit }) {
    commit('CLEAR_USER_DATA');
    router.push('/login');
  },
  async fetchUser({ commit, state }) {
    try {
      const decodedToken = jwtDecode(state.accessToken);
      const username = decodedToken[USERNAME_CLAIM];
      const merchantId = decodedToken[MERCHANT_CLAIM];

      const roles = Array.isArray(decodedToken[ROLES_CLAIM]) 
        ? decodedToken[ROLES_CLAIM]
        : [decodedToken[ROLES_CLAIM]];

      const permissions = Array.isArray(decodedToken[PERMISSIONS_CLAIM])
        ? decodedToken[PERMISSIONS_CLAIM]
        : [decodedToken[PERMISSIONS_CLAIM]];

      commit('SET_USER', { username, roles, permissions, merchantId });
    } catch (error) {
      commit('CLEAR_USER_DATA');
      router.push('/login');
    }
  },
  async refreshToken({ commit, dispatch, state }) {
    try {
      const response = await AuthAPI.refreshToken(state.refreshToken);
      const { accessToken, refreshToken } = response.data;
      commit('SET_ACCESS_TOKEN', accessToken);
      commit('SET_REFRESH_TOKEN', refreshToken);
      await dispatch('fetchUser');
    } catch (error) {
      // Handle refresh token error
      commit('CLEAR_USER_DATA');
      router.push('/login');
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
<template>
  <div class="login-container" style="padding-top: -60px;">
    <div class="login-card">
      <div class="login-content">
        <div class="login-header">
          <img src="../../public/img/logo2.svg" height="100px" width="100px" alt="yapay logo"/>
          <h2 class="login-title">YaPay Dashboard</h2>
        </div>
        <form @submit.prevent="login" class="login-form">
          <span class="p-input-icon-left w-full">
            <i class="pi pi-user"></i>
            <InputText
                id="username"
                v-model="username"
                placeholder="Username"
                :class="{'p-invalid': errorMessage}"
                class="w-full"
            />
          </span>
          <div class="p-input-icon-left w-full custom-password-wrapper">
            <i class="pi pi-lock"></i>
            <Password
                id="password"
                v-model="password"
                placeholder="Password"
                :feedback="false"
                :toggleMask="true"
                :class="{'p-invalid': errorMessage}"
                class="w-full"
                inputClass="w-full p-pl-5"
            />
          </div>
          <Button
              type="submit"
              label="Login"
              :disabled="loading"
              :loading="loading"
              class="login-button w-full p-button-raised"
          />
        </form>
        <Message v-if="errorMessage" severity="error" :closable="false" class="login-error">
          {{ errorMessage }}
        </Message>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Button from 'primevue/button';
import Message from 'primevue/message';

export default {
  name: 'LoginPage',
  components: {
    InputText,
    Password,
    Button,
    Message,
  },
  data() {
    return {
      username: '',
      password: '',
      errorMessage: '',
      loading: false,
    };
  },
  computed: {
    ...mapState(['isDarkTheme']),
  },
  methods: {
    async login() {
      this.loading = true;
      this.errorMessage = '';
      try {

        if (!this.username || !this.password) {
          this.errorMessage = 'Please enter username and password';
          return;
        }

        await this.$store.dispatch('auth/login', {
          username: this.username,
          password: this.password,
        });
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.errorMessage = 'Invalid username or password';
        } else {
          this.errorMessage = error.message || 'Failed to login';
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: var(--surface-ground);
  padding: 2rem;
}

.login-card {
  width: 100%;
  max-width: 400px;
  background-color: var(--surface-card);
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.03), 0 0 2px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.12);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.login-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.06), 0 0 4px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.16);
}

.login-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.login-header img {
  margin-right: 0.1rem;
}

.login-content {
  padding: 2.5rem;
}

.login-title {
  font-size: 1.75rem;
  color: var(--primary-color);
  font-weight: 700;
  text-align: left;
  letter-spacing: 0.5px;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

:deep(.p-inputtext),
:deep(.p-password input) {
  width: 100%;
  padding: 0.875rem 0.875rem 0.875rem 2.75rem;
  background-color: var(--surface-ground);
  border: 1px solid var(--surface-border);
  border-radius: 0.5rem;
  font-size: 1rem;
  color: var(--text-color);
  transition: border-color 0.3s, box-shadow 0.3s;
}

:deep(.p-inputtext:enabled:hover),
:deep(.p-password input:enabled:hover) {
  border-color: var(--primary-color);
}

:deep(.p-inputtext:enabled:focus),
:deep(.p-password input:enabled:focus) {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-color);
  border-color: var(--primary-color);
}

:deep(.p-input-icon-left) {
  width: 100%;
  position: relative;
}

:deep(.p-input-icon-left i) {
  position: absolute;
  top: 50%;
  left: 0.875rem;
  transform: translateY(-50%);
  color: var(--text-color-secondary);
  z-index: 1;
}

.custom-password-wrapper {
  position: relative;
}

:deep(.custom-password-wrapper .p-password) {
  width: 100%;
}

:deep(.custom-password-wrapper .p-password input) {
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}

:deep(.custom-password-wrapper .p-password i.p-icon) {
  position: absolute;
  top: 50%;
  right: 0.875rem;
  transform: translateY(-50%);
  z-index: 1;
  color: var(--text-color-secondary);
}

.login-button {
  margin-top: 0.5rem;
  padding: 0.875rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  transition: background-color 0.3s, border-color 0.3s, transform 0.3s;
}

.login-button:enabled:hover {
  background-color: var(--primary-600);
  border-color: var(--primary-600);
  transform: translateY(-2px);
}

.login-error {
  margin-top: 1rem;
}

/* Dark theme specific adjustments */
:root[data-theme="dark"] .login-card {
  background-color: var(--surface-card);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.2), 0 2px 6px rgba(0, 0, 0, 0.3);
}

:root[data-theme="dark"] .login-card:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2), 0 0 4px rgba(0, 0, 0, 0.3), 0 4px 12px rgba(0, 0, 0, 0.4);
}
</style>
<template>
  <div class="surface-ground px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center" :class="{ 'dark-theme': isDarkTheme }">
    <div class="text-center">
      <Card class="mb-5 card-body">
        <template #title>
          <h2 class="text-600">404 - Page Not Found</h2>
        </template>
        <template #content>
          <p class="text-600 mb-5">Sorry, the page you are looking for does not exist.</p>
          <Button label="Go Back Home" icon="pi pi-home" class="back-button" @click="goHome" />
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { mapState } from 'vuex';
import Card from 'primevue/card';
import Button from 'primevue/button';

export default {
  name: 'NotFoundPage',
  components: {
    Card,
    Button
  },
  computed: {
    ...mapState(['isDarkTheme']),
  },
  setup() {
    const router = useRouter();

    const goHome = () => {
      router.push('/');
    };

    return {
      goHome
    };
  }
};
</script>

<style scoped>
.surface-ground {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, color 0.3s;
}

.card-body {
  padding: 10px 60px;
}

.surface-ground.dark-theme {
  background-color: var(--surface-ground);
  color: var(--text-color);
}

.back-button {
  padding: 0.875rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  transition: background-color 0.3s, border-color 0.3s, transform 0.3s;
}

:deep(.p-card) {
  background-color: var(--surface-card);
  color: var(--text-color);
}

:deep(.p-card .p-card-title) {
  color: var(--text-color-secondary);
}

:deep(.p-button) {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

:deep(.p-button:hover) {
  background-color: var(--primary-600);
  border-color: var(--primary-600);
}
</style>

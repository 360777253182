import GatewayAPI from '@/api/gateway';

const state = {
  merchants: [],
};

const mutations = {
  SET_MERCHANTS(state, merchants) {
    state.merchants = merchants;
  },
};

const actions = {
  async fetchMerchants({ commit }) {
    try {
      const response = await GatewayAPI.getMerchants();
      commit('SET_MERCHANTS', response.data);
    } catch (error) {
      // Handle error
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

import GatewayAPI from '@/api/gateway';

const mutations = {
  SET_VALIDATION_ENDPOINTS(state, validationEndpoints) {
    state.validationEndpoints = validationEndpoints;
  }
};

const actions = {
  async fetchValidationEndpointsByCollabId({ commit }, collabId) {
    try {
      commit('SET_VALIDATION_ENDPOINTS', null);
      const response = await GatewayAPI.getValidationEndpointsByCollabId(collabId);
      commit('SET_VALIDATION_ENDPOINTS', response.data);
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        throw new Error(error.response.data.message);
      }
    }
  },

  async createValidationEndpoint({ dispatch }, { collabId, validationEndpoint }) {
    try {
      const response = await GatewayAPI.createValidationEndpoint(validationEndpoint);
      await dispatch('fetchValidationEndpointsByCollabId', collabId);
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        throw new Error(error.response.data.message);
      }
    }
  },

  async updateValidationEndpoint({ dispatch }, { collabId, validationEndpointId, validationEndpoint }) {
    try {
      let response = await GatewayAPI.updateValidationEndpoint(validationEndpointId, validationEndpoint);
      await dispatch('fetchValidationEndpointsByCollabId', collabId);

      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        throw new Error(error.response.data.message);
      }
    }
  },

  async deleteValidationEndpoint({ dispatch }, { collabId, validationEndpointId }) {
    try {
      await GatewayAPI.deleteValidationEndpoint(validationEndpointId);
      await dispatch('fetchValidationEndpointsByCollabId', collabId);
    } catch (error) {
      if (error.response.status === 500) {
        throw new Error(error.response.data.message);
      }
    }
  }
};

export default {
  namespaced: true,
  actions,
  mutations,
};
import GatewayAPI from "@/api/gateway";

const actions = {
  async fetchStatistics(_, { currency, from, to }) {
    try {
      const response = await GatewayAPI.getStatistics(currency, from, to);
      return response.data;
    } catch (error) {
      console.error("Error fetching statistics:", error);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  actions,
};

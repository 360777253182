import GatewayAPI from '@/api/gateway';

const state = {
  providers: [],
};

const mutations = {
  SET_PROVIDERS(state, providers) {
    state.providers = providers;
  },
};

const actions = {
  async fetchProviders({ commit }) {
    try {
      const response = await GatewayAPI.getProviders();
      commit('SET_PROVIDERS', response.data);
      return response.data;
    } catch (error) {
      // Handle error
    }
  },
  async updateProviderActiveness({ dispatch }, { providerId, isEnabled }) {
    try {
      await GatewayAPI.postProviderActiveness(providerId, isEnabled);
      dispatch('fetchProviders');
    } catch (error) {
      // Handle error
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

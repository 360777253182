import axios from 'axios';

const BASE_URL = process.env.VUE_APP_AUTH_API_BASE_URL;

export default {

  login(credentials) {
    return axios.post(`${BASE_URL}/api/v1/login`, credentials);
  },

  refreshToken(refreshToken) {
    return axios.post(`${BASE_URL}/api/v1/refresh-token`, { refreshToken: refreshToken });
  },

  validateToken(token) {
    return axios.get(`${BASE_URL}/api/v1/validate-token`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

};
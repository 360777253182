import axios from 'axios';
import store from '@/store';
//import router from '@/router';

// adds the access token to the request headers
const requestInterceptor = axios.interceptors.request.use(
  (config) => {
    const accessToken = store.state.auth.accessToken;
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// handles 401 responses by attempting to refresh 
// the token and retrying the original request
const authInterceptor = axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    
    if (error.response === undefined) {
      return Promise.reject(error);
    }

    if (error.response.status === 401 && error.request.responseURL.includes('/refresh-token')) {
      store.dispatch('auth/logout');
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry && store.state.auth.refreshToken) {
      originalRequest._retry = true;

      try {
        await store.dispatch('auth/refreshToken');
        const accessToken = store.state.auth.accessToken;
        originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
        return axios(originalRequest);
      } catch (refreshError) {
        store.dispatch('auth/logout');
        return Promise.reject(refreshError);
      }
    }

    // if (error.response.status === 403) {
    //   router.push('/');
    // }

    return Promise.reject(error);
  }
);

export { requestInterceptor, authInterceptor };

<template>
  <div class="page-container">
    <div class="transactions">
      <div class="container-fluid">
        <h2 class="mb-4">Transactions</h2>
        <div v-if="!isAdmin && !user.merchantId">
          <p class="text-danger">You don't have permission to view this page.</p>
        </div>
        <template v-else>
          <div class="table-container table-responsive" :class="{ 'dark-theme': isDarkTheme }">
            <DataTable lazy v-model:expandedRows="expandedRows" :value="transactions" dataKey="id" :loading="isLoading"
              :rows="pageSize" paginator responsiveLayout="stack" :totalRecords="transactionsTotal"
              @page="changePage($event)" :rowsPerPageOptions="[5, 10, 20, 30]" :rowHover="true"
              paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="{first} to {last} of {totalRecords}" :paginatorPosition="'bottom'"
              :alwaysShowPaginator="true" :loading-text="'Loading transactions...'" :resizableColumns="true" style="width: 100%"
              showGridlines>
              <template #header>
                <div class="table-header p-fluid p-2 m-2">
                  <div class="p-field">
                    <label for="collab-select" class="pb-2">{{ isAdmin ? 'Collab' : 'Provider' }}</label>
                    <Dropdown id="collab-select" v-model="selectedCollabId" checkmark
                      :disabled="isLoading || search !== ''" :options="formattedCollabs" optionValue="id"
                      optionLabel="label" :filter="true" :placeholder="isAdmin ? 'Select Collab' : 'Select Provider'"
                      class="w-full" :class="{ 'p-input-dark': isDarkTheme }">
                      <template #option="slotProps">
                        <div v-if="isAdmin" class="flex align-items-center">
                          {{ slotProps.option.merchant.name }} - {{ slotProps.option.provider.name }}
                        </div>
                        <div v-else>
                          {{ slotProps.option.provider.name }}
                        </div>
                      </template>
                    </Dropdown>
                  </div>
                  <div class="p-field">
                    <label for="transaction-type" class="pb-2">Transaction Type</label>
                    <Dropdown id="transaction-type" v-model="transactionTypeFilter" :options="transactionTypeOptions"
                      optionLabel="label" optionValue="value" placeholder="Select Transaction Type"
                      :disabled="isLoading || search !== ''" class="w-full" :class="{ 'p-input-dark': isDarkTheme }">
                    </Dropdown>
                  </div>
                  <div class="p-field">
                    <label for="date-range" class="pb-2">Date Range</label>
                    <Calendar id="date-range" v-model="dateRange" selectionMode="range" :manualInput="false"
                      :disabled="isLoading || search !== ''" :showButtonBar="true" :max-date="new Date()"
                      :min-date="new Date(2024, 2, 1)" showIcon iconDisplay="input" :numberOfMonths="2"
                      :showOtherMonths="true" :selectOtherMonths="true" dateFormat="yy-mm-dd"
                      placeholder="Select Date Range" :class="{ 'p-input-dark': isDarkTheme }" class="w-full" />
                  </div>
                  <div class="p-field search-and-clear">
                    <div class="search-field">
                      <label for="search" class="pb-2">Search Transaction</label>
                      <IconField iconPosition="right">
                        <InputIcon class="pi pi-barcode"> </InputIcon>
                        <InputText :disabled="isLoading" id="search" v-model="search"
                          placeholder="Transaction ID or Reference ID" class="w-full" />
                      </IconField>
                    </div>
                  </div>
                  <div class="p-field" style="max-width: min-content; margin-top: -10px;">
                    <Button label="Clear" icon="pi pi-filter-slash" @click="clearFilters" text />
                    <Button label="Search" icon="pi pi-search" @click="fetchTransactions" />
                  </div>
                </div>
              </template>

              <template #empty>
                <div v-if="!isLoading" class="d-flex justify-content-center m-3">
                  <p>No transactions to be displayed</p>
                </div>
              </template>

              <Column expander style="width: 1rem" />
              <Column field="id" header="ID" :headerStyle="{ position: 'sticky', top: '0' }" class="id-column">
                <template #body="{ data }">
                  <div class="id-cell">{{ data.id }}</div>
                </template>
              </Column>
              <Column field="amount" header="Amount">
                <template #body="{ data }">
                  <span v-if="data.transactionType == 1" class="text-success strong">+{{ data.amount }} {{ data.currency }}</span>
                  <span v-else class="text-danger">-{{ data.amount }} {{ data.currency }}</span>
                  
                </template>
              </Column>
              <Column field="createdUtc" header="Created">
                <template #body="{ data }">
                  {{ formatDate(data.createdUtc) }}
                </template>
              </Column>
              <Column field="updatedUtc" header="Updated">
                <template #body="{ data }">
                  {{ formatDate(data.updatedUtc) }}
                </template>
              </Column>
              <Column field="transactionStatusMessage" header="Status"></Column>
              <Column field="callbackDeliveryStatus" header="Callback Status"></Column>
              <Column field="actions" header="Actions">
                <template #body="{ data }">
                  <Button label="Callbacks" icon="pi pi-external-link" @click="showCallbackAttempts(data.id)"
                    class="p-button-outlined p-button-secondary" />
                </template>
              </Column>
              <template #expansion="{ data }">
                <div class="p-grid">
                  <div class="p-col-12 p-md-6 mb-2">
                    <strong>Merchant Name:</strong> {{ data.merchantName }}
                  </div>
                  <div class="p-col-12 p-md-6 mb-2">
                    <strong>Provider Name:</strong> {{ data.providerName }}
                  </div>
                  <div class="p-col-12 p-md-6 mb-2">
                    <strong>Transaction Type:</strong> {{ data.transactionType == 1 ? 'Cash In' : 'Cash Out' }}
                  </div>
                  <div class="p-col-12 p-md-6 mb-2">
                    <strong>Provider Transaction ID:</strong> {{ data.providerTransactionId }}
                  </div>
                  <div class="p-col-12 p-md-6 mb-2">
                    <strong>Nonce:</strong> {{ data.nonce }}
                  </div>
                  <div class="p-col-12 mb-2">
                    <strong>Account Data:</strong>
                  </div>
                  <div class="p-col-12">
                    <pre class="small">{{ formatJsonData(data.accountData) }}</pre>
                  </div>
                </div>
              </template>
              <template #paginatorstart>
                <div v-if="error" class="text-danger p-2">{{ error }}</div>
                <Button type="button" icon="pi pi-refresh" text @click="fetchTransactions" />
              </template>
            </DataTable>
          </div>
        </template>
      </div>
      <Dialog v-model:visible="showCallbackAttemptsModal" header="Callback Attempts" :modal="true" :maximizable="true"
        :style="{ width: '80rem' }" :breakpoints="{ '1199px': '80vw', '575px': '90vw' }" :darkMode="isDarkTheme">
        <DataTable :value="callbackAttempts" :loading="isLoading" :rows="10" class="callback-attempts-table dark-table">

          <template #empty>
            <div v-if="!isLoading" class="d-flex justify-content-center m-3">
              <p>No callbacks to be displayed</p>
            </div>
          </template>

          <Column field="id" header="ID" class="id-column"></Column>
          <Column field="createdUtc" header="Created">
            <template #body="{ data }">
              {{ formatDate(data.createdUtc) }}
            </template>
          </Column>
          <Column field="callbackUrl" header="URL"></Column>
          <Column field="requestBody" header="Request Body">
            <template #body="{ data }">
              <Button :label="data.showRequestBody ? 'Hide' : 'Show'" @click="toggleRequestBody(data)"
                class="p-button-outlined p-button-secondary p-button-sm" />
              <pre v-if="data.showRequestBody" class="small mt-4">{{ formatJsonData(data.requestBody) }}</pre>
            </template>
          </Column>
          <Column field="responseCode" header="Response Code"></Column>
          <Column field="callbackAttemptStatus" header="Status"></Column>
          <Column field="actions" header="Actions">
            <template #body="{ data }">
              <div>
                <template v-if="isCallbackAttemptFailed(data)">
                  <Button v-if="!hasCallbackAttemptBeenRetried(data)" label="Retry" @click="retryCallback(data.id)"
                    class="p-button-sm p-button-secondary" :loading="isRetryingCallbackForId(data)" />
                  <div v-else>Request sent</div>
                </template>
              </div>
            </template>
          </Column>
        </DataTable>
      </Dialog>
      <Toast />
    </div>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import Calendar from 'primevue/calendar';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import Toast from 'primevue/toast';
import { useToast } from "primevue/usetoast";

export default {
  components: {
    DataTable,
    Column,
    Dropdown,
    Button,
    InputText,
    Dialog,
    Calendar,
    IconField,
    InputIcon,
    Toast,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  name: 'TransactionsPage',
  data() {
    return {
      selectedCollabId: '',
      currentPage: 0,
      pageSize: 5,
      transactions: [],
      transactionsTotal: 0,
      transactionsTotalPages: 0,
      callbackAttempts: [],
      currentRetryingIds: [],
      retriedCallbackAttempts: [],
      isLoading: false,
      isLoadingCollabs: false,
      isRetryingCallback: false,
      error: null,
      retryError: null,
      showCallbackAttemptsModal: false,
      search: '',
      dateRange: null,
      transactionTypeFilter: null,
      transactionTypeOptions: [
        { label: 'All', value: null },
        { label: 'Cash In', value: 1 },
        { label: 'Cash Out', value: 2 }
      ],
      expandedRows: [],
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('providers', ['providers']),
    ...mapState('collabs', {
      ...mapState('collabs', ['collabs']),
    }),
    isDarkTheme() {
      return this.$store.state.isDarkTheme;
    },
    isAdmin() {
      return this.user && this.user.roles.includes('Admin');
    },
    formattedCollabs() {
      return this.collabs.map(collab => ({
        ...collab,
        label: this.isAdmin
          ? `${collab.merchant.name} - ${collab.provider.name}`
          : collab.provider.name
      }));
    },
    first() {
      return this.currentPage * this.pageSize + 1;
    },
    last() {
      return Math.min(this.currentPage * this.pageSize + this.pageSize, this.transactionsTotal);
    }
  },
  async created() {
    this.isLoadingCollabs = true;
    try {
      if (this.isAdmin) {
        const providers = await this.fetchProviders();
        await this.fetchCollabsByProviderIds(providers.map(provider => provider.id));
      }
      else {
        await this.fetchCollabsByMerchantId(this.user.merchantId);
      }
    } catch (error) {
      this.error = 'Failed to load collaborations: ' + error.message;
    } finally {
      this.isLoadingCollabs = false;
    }
  },
  methods: {
    ...mapActions('providers', ['fetchProviders']),
    ...mapActions('collabs', ['fetchCollabsByMerchantId', 'fetchCollabsByProviderIds']),
    ...mapActions('transactions', [
      'fetchTransactionById',
      'fetchTransactionByReferenceId',
      'fetchTransactionsByCollabId',
      'fetchCallbackAttemptsByTransactionId',
      'retryCallbackAttempt']),

    clearFilters() {
      this.selectedCollabId = '';
      this.transactionTypeFilter = null;
      this.dateRange = null;
      this.search = '';
      this.fetchTransactions();
    },

    async fetchTransactions() {
      try {
        this.error = null;
        this.isLoading = true;

        if (this.search) {
          await this.searchTransactions();
        } else if (this.selectedCollabId) {
          const startDate = this.dateRange ? this.dateRange[0].toISOString() : undefined;
          const endDate = this.dateRange ? this.dateRange[1].toISOString() : undefined;
          const transactionType = this.transactionTypeFilter;
          const response = await this.fetchTransactionsByCollabId({
            collabId: this.selectedCollabId,
            transactionType: transactionType,
            page: this.currentPage + 1,
            size: this.pageSize,
            startDate: startDate,
            endDate: endDate,
          });
          this.transactions = response.transactions;
          this.transactionsTotal = response.totalCount;
          this.transactionsTotalPages = response.totalPages;
          this.currentPage = response.currentPage - 1;
        }
      } catch (error) {
        this.error = 'Failed to fetch transactions. Please try again.';
        this.toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to fetch transactions: ' + error.message, life: 5500 });
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    async searchTransactions() {
      if (this.search) {
        try {
          var response;
          if (this.search.match(/^\d+$/)) {
            response = await this.fetchTransactionById(parseInt(this.search));
          } else {
            response = await this.fetchTransactionByReferenceId(this.search);
          }

          if (response) {
            this.transactions = [response];
            this.transactionsTotal = 1;
          } else {
            this.transactions = [];
            this.transactionsTotal = 0;
          }

          this.transactionsTotalPages = 1;
          this.currentPage = 1;

        } catch (error) {
          this.error = 'Failed to search transactions. Please try again.';
          this.toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to find transaction: ' + error.message, life: 5500 });
          console.error(error);
        }
      } else {
        await this.fetchTransactions();
      }
    },

    async changePage(event) {
      this.currentPage = event.page;
      this.pageSize = event.rows;
      await this.fetchTransactions();
    },

    async changeItemsPerPage(size) {
      this.pageSize = size;
      await this.fetchTransactions();
    },

    async showCallbackAttempts(transactionId) {
      try {
        this.error = null;
        this.isLoading = true;
        const response = await this.fetchCallbackAttemptsByTransactionId({ transactionId, page: 1, size: 100 });
        this.callbackAttempts = response.callbackAttempts;
        this.showCallbackAttemptsModal = true;
      } catch (error) {
        this.error = 'Failed to fetch callback attempts. Please try again.';
        this.toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to fetch callback attempts: ' + error.message, life: 5500 });
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    async retryCallback(callbackAttemptId) {
      try {
        this.retryError = null;
        this.isRetryingCallback = true;
        this.currentRetryingIds.push(callbackAttemptId);
        this.toast.add({ severity: 'info', summary: 'Retrying...', detail: 'Adding a callback to the retry queue', life: 3000 });
        await this.retryCallbackAttempt(callbackAttemptId);
        this.retriedCallbackAttempts.push(callbackAttemptId);
        this.toast.add({ severity: 'success', summary: 'Successful', detail: 'Callback added to the retry queue. You will be able to check its status after a while.', life: 5500 });
      } catch (error) {
        this.retryError = 'Failed to retry callback attempt. Please try again.';
        this.toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to add callback to the retry queue: ' + error.message, life: 5500 });
      } finally {
        this.currentRetryingIds = this.currentRetryingIds.filter(id => id !== callbackAttemptId);
        this.isRetryingCallback = false;
      }
    },

    isCallbackAttemptFailed(data) {
      return data.callbackAttemptStatus === 'Failed';
    },

    hasCallbackAttemptBeenRetried(data) {
      return this.retriedCallbackAttempts.includes(data.id);
    },

    isRetryingCallbackForId(data) {
      return this.currentRetryingIds.includes(data.id);
    },

    formatDate(date) {
      return new Date(date).toLocaleString();
    },

    toggleRequestBody(attempt) {
      attempt.showRequestBody = !attempt.showRequestBody;
    },

    formatJsonData(jsonData) {
      if (typeof jsonData === 'string') {
        try {
          jsonData = JSON.parse(jsonData);
        } catch (e) {
          return jsonData;
        }
      }
      return JSON.stringify(jsonData, null, 2);
    },
  },
};
</script>

<style scoped>

.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.transactions {
  padding: 60px 10px 10px;
  display: flex;
  flex-direction: column;
}

.transaction-details pre {
  overflow-x: auto;
  max-height: 300px;
}

.table-container {
  background-color: #ffffff;
  border-radius: 1rem;
  padding: 1.2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  border: 2px solid #e0e0e0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
}

.table-container.dark-theme {
  background-color: #18181B;
  color: rgb(248, 248, 248);
  border-color: #41414e;
}

.p-datatable .p-datatable-header {
  border: none !important;
  border-width: 0;
  margin: 1rem;
  height: max-content !important;
}

.table-header {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
}

.table-header .p-field {
  flex: 1 1 300px;
}

.table-header .search-and-clear {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.search-field {
  flex: 3 1 60%;
}

.action-buttons {
  flex: 1 1 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-responsive {
  flex: 1;
  overflow: hidden;
}

.table-responsive .p-datatable-wrapper {
  flex: 1;
  overflow: auto;
}

.table-responsive .p-datatable-thead th,
.table-responsive .p-datatable-tbody td {
  white-space: nowrap;
}

@media (max-width: 600px) {
  .transactions-table .p-datatable-wrapper {
    overflow-x: auto;
  }

  table {
    font-size: 12px;
  }

  .transactions-table .p-datatable-thead th,
  .transactions-table .p-datatable-tbody td {
    white-space: nowrap;
  }

  .table-responsive .p-datatable-wrapper {
    overflow-x: auto;
  }

  .table-responsive .p-datatable-thead th,
  .table-responsive .p-datatable-tbody td {
    white-space: nowrap;
  }

  .table-header .p-field,
  .search-field,
  .action-buttons {
    flex: 1 1 100%;
  }

  .action-buttons {
    margin-top: 1rem;
  }

  .table-header,
  .table-container {
    padding: 0.5rem; 
  }
}
</style>

<template>
  <v-app :class="{ dark: isDarkTheme }">
    <div id="app" :class="{ 'dark-theme': isDarkTheme }">
      <div class="d-flex">
        <button v-if="isLoggedIn" class="btn btn-secondary toggle-btn" @click="toggleNav">
          <i class="fas fa-bars"></i>
        </button>
        <AppNavigation v-if="isLoggedIn" :isOpen="isNavOpen" @toggle="toggleNav" />
        <div class="main-content flex-grow-1" :class="{ 'main-content, nav-open': isNavOpen && isLoggedIn }">
          <router-view />
        </div>
      </div>
    </div>
  </v-app>

</template>

<script>
import { mapState } from 'vuex';
import loadTheme from '@/utils/themeManager';
import AppNavigation from '@/components/AppNavigation.vue';

export default {
  name: 'App',
  components: {
    AppNavigation,
  },
  data() {
    return {
      isNavOpen: false,
    };
  },
  computed: {
    ...mapState(['isDarkTheme']),
    ...mapState('auth', ['user']),
    isLoggedIn() {
      return !!this.user;
    },
  },
  watch: {
    isDarkTheme(newValue) {
      if (newValue){
        loadTheme('aura-light-green', 'aura-dark-green')
      } else {
        loadTheme('aura-dark-green', 'aura-light-green')
      }
    }
  },
  created() {
    this.$store.dispatch('auth/fetchUser');
    if (this.isDarkTheme) {
        loadTheme('aura-light-green', 'aura-dark-green');
    } else {
        loadTheme('aura-dark-green', 'aura-light-green');
    }
  },
  methods: {
    toggleNav() {
      this.isNavOpen = !this.isNavOpen;
    },
  },
};
</script>

<style>
body {
  background-color: #f0f0f0;
}

.main-content {
  min-height: 100vh;
  transition: background-color 0.3s, margin-left 0.3s;
  margin-left: 0;
}

.main-content.nav-open {
  margin-left: 250px;
}

.dark-theme {
  background-color: #333;
  color: #fff;
}

.toggle-btn {
  position: fixed;
  top: 15px;
  left: 20px;
  z-index: 1000;
}

/* .main-content.nav-open {
  margin-left: 250px;
}

@media (max-width: 767px) {
  .main-content.nav-open {
    margin-left: 0;
  }
} */
</style>
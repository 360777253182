<template>
  <div>
    <div class="modal-backdrop" v-if="show" @click="closeModal"></div>
    <transition name="fade">
      <div v-if="show" class="modal" tabindex="-1" role="dialog" @click="closeModalOnBackdropClick">
        <div :class="['modal-dialog', 'modal-dialog-centered', 'modal-dialog-scrollable', { 'modal-sm': !isWideModal, 'modal-xl': isWideModal }]" @click.stop>
          <div :class="['modal-content', { 'p-3': !isWideModal, 'p-4': isWideModal }, { 'modal-dark': isDarkTheme }]">
            <div class="modal-header">
              <h5 class="modal-title">
                <slot name="header"></slot>
              </h5>
              <button type="button" @click="closeModal" aria-label="Close" :class="{ 'modal-dark': isDarkTheme, 'close-btn': true }" style="margin-left: auto;">
                <i :class="['fas', 'fa-times', { 'fa-sm': !isWideModal, 'fa-lg': isWideModal }]"></i>
              </button>
            </div>
            <div class="modal-body">
              <slot name="body"></slot>
            </div>
            <div class="modal-footer">
              <slot name="footer"></slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: 'ModalWindow',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    isDarkTheme: {
      type: Boolean,
      default: false,
    },
    isWideModal: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    isDarkTheme(newVal) {
      this.updateTheme(newVal);
    },
  },
  mounted() {
    this.updateTheme(this.isDarkTheme);
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    closeModalOnBackdropClick(event) {
      if (event.target === event.currentTarget) {
        this.closeModal();
      }
    },
    updateTheme(isDark) {
      document.documentElement.style.setProperty('--modal-text', isDark ? '#FFF' : '#000');
      document.documentElement.style.setProperty('--form-text-muted', isDark ? '#AAA' : '#555');
    },
  },
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.226);
  backdrop-filter: blur(3px);
  z-index: 999;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px;
}

.close-btn {
  background: none;
  border: none;
  color: inherit;
}

.modal-dark .close-btn .fa {
  color: #ffffff;
}

.modal-content {
  border-radius: 0.5rem;
}

.modal-dark {
  background-color: #222 !important;
  color: #FFF;
}

.modal-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.modal-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.modal-body {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
}

.form-text.text-muted {
  color: var(--form-text-muted);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
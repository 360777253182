import GatewayAPI from '@/api/gateway'; // to be changed to '@/api/callbacks'; when API will be updated

const state = {
  callbackUrls: [],
};

const mutations = {
  SET_CALLBACK_URLS(state, callbackUrls) {
    state.callbackUrls = callbackUrls;
  },
};

const actions = {
  async fetchCallbackUrlsByMerchantId({ commit }, merchantId) {
    try {
      const response = await GatewayAPI.getCallbackUrlsByMerchantId(merchantId);
      commit('SET_CALLBACK_URLS', response.data);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch callback URLs:', error);
      throw error;
    }
  },

  async createCallbackUrl({ dispatch }, { merchantId, callbackUrl }) {
    try {
      const response = await GatewayAPI.postCallbackUrl(callbackUrl);
      await dispatch('fetchCallbackUrlsByMerchantId', merchantId);
      return response.data;
    } catch (error) {
      console.error('Failed to create callback URL:', error);
      throw error;
    }
  },

  async updateCallbackUrl({ dispatch }, { merchantId, callbackUrlId, callbackUrl }) {
    try {
      await GatewayAPI.updateCallbackUrl(callbackUrlId, callbackUrl);
      await dispatch('fetchCallbackUrlsByMerchantId', merchantId);
    } catch (error) {
      console.error('Failed to update callback URL:', error);
      throw error;
    }
  },

  async deleteCallbackUrl({ dispatch }, { callbackUrlId, merchantId }) {
    try {
      await GatewayAPI.deleteCallbackUrl(callbackUrlId);
      await dispatch('fetchCallbackUrlsByMerchantId', merchantId);
    } catch (error) {
      console.error('Failed to delete callback URL:', error);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './utils/authInterceptor';
import errorHandler from './utils/errorHandler';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import PrimeVue from 'primevue/config';
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
//import 'primevue/resources/themes/aura-dark-green/theme.css'
//import 'primevue/resources/themes/aura-light-green/theme.css'

const vuetify = createVuetify({
    components,
    directives,
    icons: {
      defaultSet: 'mdi',
    },
  });

const app = createApp(App);
app.use(vuetify);
app.use(PrimeVue);
app.use(ConfirmationService);
app.use(ToastService);

app.config.globalProperties.$productionTip = false;
app.config.errorHandler = errorHandler;

app.use(router).use(store).mount('#app');

//import { usePrimeVue } from "primevue/config";

function loadTheme(oldTheme, newTheme) {
  // try {
  //     const primeVue = usePrimeVue();
  //     primeVue.changeTheme(oldTheme, newTheme, 'theme-link', () => {})
  // }
  // catch (error) {
  //     console.error('Error loading theme:', error);
  // }

  try {
    const linkElementId = "theme-link";
    var linkElement = document.getElementById(linkElementId);
    var cloneLinkElement = linkElement.cloneNode(true);
    var newThemeUrl = linkElement
      .getAttribute("href")
      .replace(oldTheme, newTheme);
    cloneLinkElement.setAttribute("id", linkElementId + "-clone");
    cloneLinkElement.setAttribute("href", newThemeUrl);
    cloneLinkElement.addEventListener("load", function () {
      linkElement.remove();
      cloneLinkElement.setAttribute("id", linkElementId);
    });
    linkElement.parentNode &&
      linkElement.parentNode.insertBefore(
        cloneLinkElement,
        linkElement.nextSibling
      );

  } catch (error) {
    console.error("Error loading theme:", error);
  }
}

export default loadTheme;
